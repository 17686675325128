import React, { useEffect, useRef } from 'react';
import thublogo from '../assets/images/thublogo.png';
import vitlogo from '../assets/images/vitlogo.png';
import ecellblack from '../assets/images/ecellblack.png';
import MeitY_Startup from '../assets/images/MeitY_Startup.png';
import Ministry_of_Electronics_and_Information_Technology from '../assets/images/Ministry_of_Electronics_and_Information_Technology.png';
import './Carousel.css'; // Assuming you move the styles to a separate CSS file

function Carousel() {

  return (
    <div class="carousel-wrapper">
    <div class="blur-edge-left"></div>
    <div class="carousel-container">
        <div class="carousel-track">
            
            <img src={thublogo} alt="Logo 2" class="carousel-item" />
            <img src={vitlogo} alt="Logo 3" class="carousel-item"/>
            <img src={ecellblack} alt="Logo 3" class="carousel-item"/>
            <img src={MeitY_Startup} alt="Logo 3" class="carousel-item"/>
            <img src={Ministry_of_Electronics_and_Information_Technology} alt="Logo 3" class="carousel-item"/>
            <img src={thublogo} alt="Logo 2" class="carousel-item"/>
            <img src={vitlogo} alt="Logo 3" class="carousel-item"/>
            <img src={ecellblack} alt="Logo 3" class="carousel-item"/>
            <img src={MeitY_Startup} alt="Logo 3" class="carousel-item"/>
            <img src={Ministry_of_Electronics_and_Information_Technology} alt="Logo 3" class="carousel-item"/>
            <img src={thublogo} alt="Logo 2" class="carousel-item"/>
            <img src={vitlogo} alt="Logo 3" class="carousel-item"/>
            <img src={ecellblack} alt="Logo 3" class="carousel-item"/>
            <img src={MeitY_Startup} alt="Logo 3" class="carousel-item"/>
            <img src={Ministry_of_Electronics_and_Information_Technology} alt="Logo 3" class="carousel-item"/>
            <img src={thublogo} alt="Logo 2" class="carousel-item"/>
            <img src={vitlogo} alt="Logo 3" class="carousel-item"/>
            <img src={ecellblack} alt="Logo 3" class="carousel-item"/>
            <img src={MeitY_Startup} alt="Logo 3" class="carousel-item"/>
            <img src={Ministry_of_Electronics_and_Information_Technology} alt="Logo 3" class="carousel-item"/>
            
        </div>
    </div>
    <div class="blur-edge-right"></div>
</div>

  );
}

export default Carousel;
