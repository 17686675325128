import React from 'react';
import './FimInsightsCard.css';

function FimInsightsCard({ title, description, image, cardClass }) {
  return (
    <div className={`fim-insights-card ${cardClass}`}>
      <h3>{title}</h3>
      <p>{description}</p>
      <img src={image} alt={`${title} Image`} />
    </div>
  );
}

export default FimInsightsCard;